import React from 'react'
import call from '../../assets/images/call.svg'
import globe from '../../assets/images/globe.svg'
import map from '../../assets/images/location.svg'
import mail from '../../assets/images/mail.svg'
import { convertToRgbColor } from '../../globalFunctions'


const NetworkBottomIcons = ({ bottomIconsStyling, opacityValue }) => {
    const rgbIconsBg = convertToRgbColor(bottomIconsStyling?.backgroundColor);
    const iconsBgColor = `rgba(${rgbIconsBg[0]}, ${rgbIconsBg[1]}, ${rgbIconsBg[2]}, ${parseInt(opacityValue) / 100})`;

    return (
        <div className="network_bottomBar">
            <div className="wrapper" style={{ backgroundColor : iconsBgColor }}>
                <img src={mail} alt="" />
            </div>
            <div className="wrapper" style={{ backgroundColor : iconsBgColor }}>
                <img src={call} alt="" />
            </div>
            <div className="wrapper" style={{ backgroundColor : iconsBgColor }}>
                <img src={map} alt="" />
            </div>
            <div className="wrapper" style={{ backgroundColor : iconsBgColor }}>
                <img src={globe} alt="" />
            </div>
        </div>
    )
}

export default NetworkBottomIcons
