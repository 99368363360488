import india from '../../assets/flags/in-flag.webp';
import canada from '../../assets/flags/ca-flag.webp';
import malaysia from '../../assets/flags/my-flag.webp';
import qatar from '../../assets/flags/qa-flag.webp';
import australia from '../../assets/flags/au-flag.webp';
import bahrain from '../../assets/flags/bh-flag.webp';
import france from '../../assets/flags/fr-flag.webp';
import laos from '../../assets/flags/la-flag.webp';
import poland from '../../assets/flags/pl-flag.webp';
import portugal from '../../assets/flags/pt-flag.webp';
import uk from '../../assets/flags/uk-flag.webp';
import usa from '../../assets/flags/us-flag.webp';
import southAfrica from "../../assets/flags/sf-flag.webp";

export const countryCodesData =[
    {
      id: '0',
      label: 'AU',
      countryName: 'Australia',
      maxDigits: 9,
      countryCode: '+61',
      countryFlagIcon: australia
    },
    {
      id: '1',
      label: 'Bahrain',
      countryName: 'Bahrain',
      maxDigits: 8,
      countryCode: '+973',
      countryFlagIcon: bahrain
    },
    {
      id: '2',
      label: 'Canada',
      countryName: 'Canada',
      maxDigits: 10,
      countryCode: '+1',
      countryFlagIcon: canada
    },
    {
      id: '3',
      label: 'France',
      countryName: 'France',
      maxDigits: 9,
      countryCode: '+33',
      countryFlagIcon: france 
    },
    {
      id: '4',
      label: 'IN',
      countryName: 'India',
      maxDigits: 10,
      countryCode: '+91',
      countryFlagIcon: india 
    },
    {
      id: '5',
      label: 'Laos',
      countryName: "Lao People's Democratic Republic",
      maxDigits: 8,
      countryCode: '+856',
      countryFlagIcon: laos 
    },
    {
      id: '6',
      label: 'Malaysia',
      countryName: 'Malaysia',
      maxDigits: 10,
      countryCode: '+60',
      countryFlagIcon: malaysia
    },
    {
      id: '7',
      label: 'Poland',
      countryName: 'Poland',
      maxDigits: 9,
      countryCode: '+48',
      countryFlagIcon: poland 
    },
    {
      id: '8',
      label: 'Portugal',
      countryName: 'Portugal',
      maxDigits: 9,
      countryCode: '+351',
      countryFlagIcon: portugal
    },
    {
      id: '9',
      label: 'Qatar',
      countryName: 'Qatar',
      maxDigits: 8,
      countryCode: '+974',
      countryFlagIcon: qatar
    },
    {
      id: '10',
      label: 'South Africa',
      countryName: 'South Africa',
      maxDigits: 9,
      countryCode: '+27',
      countryFlagIcon: southAfrica 
    },
    {
      id: '11',
      label: 'United Kingdom',
      countryName: 'United Kingdom',
      maxDigits: 10,
      countryCode: '+44',
      countryFlagIcon: uk 
    },
    {
      id: '12',
      label: 'United States of America',
      countryName: 'United States of America',
      maxDigits: 10,
      countryCode: '+1',
      countryFlagIcon: usa
    }
  ]
  