const FeedbackStaticTextTop = () => {
  return (
    <>
        <div className="user-feedback-header-text">Rate your experience!</div>
        <div className="user-feedback-sub-header-text user-feedback-sub-header-text-mt">What do you think about this screen?</div>
        <div className="user-feedback-sub-header-text">Give your valuable feedback</div>
    </>
  )
}

export default FeedbackStaticTextTop;