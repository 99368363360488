import emojiFive from "../../../../assets/images/feedback_emoji_5.png";
import emojiFour from "../../../../assets/images/feedback_emoji_4.png";
import emojiThree from "../../../../assets/images/feedback_emoji_3.png";
import emojiTwo from "../../../../assets/images/feedback_emoji_2.png";
import emojiOne from "../../../../assets/images/feedback_emoji_1.png";

export const emojiData = [
    {
        id: "0",
        emojiFeedbackRating: 1,
        emojiIcon: emojiOne,
        emojiBottomText: "Very Bad",
    },
    {
        id: "1",
        emojiFeedbackRating: 2,
        emojiIcon: emojiTwo,
        emojiBottomText: "Bad",
    },
    {
        id: "2",
        emojiFeedbackRating: 3,
        emojiIcon: emojiThree,
        emojiBottomText: "Average",
    },
    {
        id: "3",
        emojiFeedbackRating: 4,
        emojiIcon: emojiFour,
        emojiBottomText: "Good",
    },
    {
        id: "4",
        emojiFeedbackRating: 5,
        emojiIcon: emojiFive,
        emojiBottomText: "Awesome",
    },
]
