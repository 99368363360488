export const emojisData = [
    {
        id:1,
        code:'0x1F600'
    },
    {
        id:2,
        code:'0x1F603'
    },
    {
        id:3,
        code:'0x1F604'
    },
    {
        id:4,
        code:'0x1F601'
    },
    {
        id:5,
        code:'0x1F606'
    },
    {
        id:6,
        code:'0x1F605'
    },
    {
        id:7,
        code:'0x1F923'
    },
    {
        id:8,
        code:'0x1F602'
    },
    {
        id:9,
        code:'0x1F642'
    },
    {
        id:10,
        code:'0x1F643'
    },
    {
        id:11,
        code:'0x1F609'
    },
    {
        id:12,
        code:'0x1F60A'
    },
    {
        id:13,
        code:'0x1F607'
    },
    {
        id:14,
        code:'0x1F970'
    },
    {
        id:15,
        code:'0x1F60D'
    },
    {
        id:16,
        code:'0x1F929'
    },
    {
        id:17,
        code:'0x1F618'
    },
    {
        id:18,
        code:'0x1F60B'
    },
    {
        id:19,
        code:'0x1F61B'
    },
    {
        id:20,
        code:'0x1F61C'
    },
    {
        id:21,
        code:'0x1F92A'
    },
    {
        id:22,
        code:'0x1F61D'
    },
    {
        id:23,
        code:'0x1F911'
    },
    {
        id:24,
        code:'0x1F917'
    },
    {
        id:25,
        code:'0x1F92D'
    },
    {
        id:26,
        code:'0x1F92B'
    },
    {
        id:27,
        code:'0x1F914'
    },
    {
        id:28,
        code:'0x1F910'
    },
    {
        id:29,
        code:'0x1F611'
    },
    {
        id:30,
        code:'0x1F60F'
    },
    {
        id:31,
        code:'0x1F644'
    },
    {
        id:32,
        code:'0x1F62C'
    },
    {
        id:33,
        code:'0x1F614'
    },
    {
        id:34,
        code:'0x1F924'
    },
    {
        id:35,
        code:'0x1F634'
    },
    {
        id:36,
        code:'0x1F637'
    },
    {
        id:37,
        code:'0x1F912'
    },
    {
        id:38,
        code:'0x1F915'
    },
    {
        id:39,
        code:'0x1F92E'
    },
    {
        id:40,
        code:'0x1F973'
    },
    {
        id:41,
        code:'0x1F60E'
    },
    {
        id:42,
        code:'0x1F9D0'
    },
    {
        id:43,
        code:'0x1F615'
    },
    {
        id:44,
        code:'0x1F641'
    },
    {
        id:45,
        code:'0x1F62E'
    },
    {
        id:46,
        code:'0x1F633'
    },
    {
        id:47,
        code:'0x1F622'
    },
    {
        id:48,
        code:'0x1F62D'
    },
    {
        id:49,
        code:'0x1F616'
    },
    {
        id:50,
        code:'0x1F62B'
    },
    {
        id:51,
        code:'0x1F971'
    },
    {
        id:52,
        code:'0x1F624'
    },
    {
        id:53,
        code:'0x1F620'
    },
    {
        id:54,
        code:'0x1F92C'
    },
    {
        id:55,
        code:'0x1F44B'
    },
    {
        id:56,
        code:'0x1F44D'
    },
    {
        id:57,
        code:'0x1F64C'
    },
    {
        id:58,
        code:'0x1F64F'
    },
    {
        id:59,
        code:'0x1F44F'
    },
    {
        id:60,
        code:'0x1F91D'
    },
    {
        id:61,
        code:'0x1F639'
    },
    {
        id:62,
        code:'0x1F63A'
    },
    {
        id:63,
        code:'0x1F63B'
    },
    {
        id:64,
        code:'0x1F63E'
    },
    {
        id:65,
        code:'0x1F63F'
    },
    {
        id:66,
        code:'0x1F640'
    },
]